<template>
  <e-modal
    :visible="visible"
    title="发送短信"
    :width="500"
    :footer="false"
    @close="onClose"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <e-textarea :valueCol="20" title="短信内容" :showCount="true" v-model:value="content" />

      <e-input title="预览手机" v-model:value="mobile" />
      <a-row>
        <a-col :offset="4">
          <a-button :loading="loading" type="primary" @click="onSendOne">发送预览手机</a-button>
          <a-button :loading="loading" type="primary" danger @click="onSendMany" style="margin-left: 15px">群发</a-button>
        </a-col>
      </a-row>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { SmsClass } from '@/apis/user'

const api = new SmsClass()
export default defineComponent({
  emits: ['ok'],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const content = ref('')
    const mobile = ref('')

    const hasPreview = ref(false)

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    return {
      visible,
      loading,
      content,
      mobile,
      hasPreview,

      onClose
    }
  },
  methods: {
    open () {
      this.visible = true
    },

    onSendOne () {
      if (!this.content) {
        this.$message.error('请填写短信内容')
        return
      }

      if (!this.mobile) {
        this.$message.error('请填写手机号码')
        return
      }

      this.loading = true
      api.sendOne(this.content, this.mobile).then(() => {
        this.$message.success('发送成功')
        this.loading = false
        this.hasPreview = true
      })
    },

    onSendMany () {
      if (!this.content) {
        this.$message.error('请填写短信内容')
        return
      }

      if (!this.hasPreview) {
        const _this = this
        this.$confirm({
          title: '警告！',
          icon: createVNode(ExclamationCircleOutlined),
          content: createVNode(
            'div',
            {
              style: 'color:red;'
            },
            '您还没有预览，确认直接群发？'
          ),
          okText: '确认发送',
          onOk () {
            _this.confirmSend()
          },

          onCancel () {
            console.log('Cancel')
          }
        })
      } else {
        this.confirmSend()
      }
    },

    confirmSend () {
      this.loading = true
      api.sendMany(this.content).then(() => {
        this.$message.success('发送成功')
        this.loading = false
      })
    }
  }
})
</script>
